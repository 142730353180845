import Script from "next/script";

const WebsiteChat = () => {
  return (
    <Script
      strategy="lazyOnload"
      dangerouslySetInnerHTML={{
        __html: `
    window.$crisp=[];window.CRISP_WEBSITE_ID="ca68c827-d358-40c5-ad79-9433e7562453";(function(){d=document;s=d.createElement("script");s.src="https://client.crisp.chat/l.js";s.async=1;d.getElementsByTagName("head")[0].appendChild(s);})();
  `,
      }}
    ></Script>
  );
};

export default WebsiteChat;
