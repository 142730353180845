/*
  This example requires Tailwind CSS v2.0+ 
  
  This example requires some changes to your config:
  
  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/aspect-ratio'),
    ],
  }
  ```
*/
export default function Example() {
  return (
    <div className="bg-white">
      <div className="max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:px-8">
        <div className="bg-primary-700 rounded-lg shadow-xl overflow-hidden lg:grid lg:grid-cols-2 lg:gap-4">
          <div className="pt-10 pb-12 px-6 sm:pt-16 sm:px-16 lg:py-16 lg:pr-0 xl:py-20 xl:px-20">
            <div className="lg:self-center">
              <div className="text-md font-semibold uppercase text-white sm:text-lg tracking-wider opacity-75">
                Limited Time
              </div>
              <h2 className="text-3xl font-extrabold text-white sm:text-4xl">
                Get a FREE Quote and Booking Form
              </h2>
              <p className="mt-4 text-lg leading-6 text-primary-200">
                Lifetime access to our form builder. Get leads and appointments
                while you sleep.
              </p>
              <a
                href="/demo"
                className="mt-8 bg-white border border-transparent rounded-md shadow px-5 py-3 inline-flex items-center text-base font-medium text-primary-600 hover:bg-primary-50"
              >
                Create New Form
              </a>
            </div>
          </div>
          <div className="-mt-6 aspect-w-5 aspect-h-3 md:aspect-w-2 md:aspect-h-1">
            <img
              className="transform translate-x-6 translate-y-6 rounded-md object-cover object-left-top sm:translate-x-16 lg:translate-y-20"
              src="/images/form_screenshot.png"
              alt="App screenshot"
            />
          </div>
        </div>
      </div>
    </div>
  );
}
