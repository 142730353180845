import {
  CalendarIcon,
  ClockIcon,
  CubeIcon,
  CurrencyDollarIcon,
  DeviceMobileIcon,
  MapIcon,
  StarIcon,
  StatusOnlineIcon,
  TruckIcon,
} from "@heroicons/react/outline";
import { useEffect, useState } from "react";
import TextTransition, { presets } from "react-text-transition";
import FancyUnderline from "../../../components-reusable/Common/FancyUnderline/FancyUnderline";

const TEXTS = ["quotes", "bookings", "leads", "appointments"];
const features = [
  {
    name: "Estimates, Invoices & Payments",
    description:
      "Charge before or after service, require authorization, and batch invoice with your Stripe account.",
    icon: CurrencyDollarIcon,
  },
  {
    name: "Dynamic Scheduling",
    description:
      "Enter your available time slots once. Clients can select from these available time slots during booking.",
    icon: ClockIcon,
  },
  {
    name: "Automated Dispatching",
    description:
      "Easily dispatch new booking to your providers/employees. Accept or decline bookings via mobile app.",
    icon: MapIcon,
  },
  {
    name: "Custom Booking Form",
    description:
      "Get all the job details, address, and client info from one booking form.",
    icon: CubeIcon,
  },
  {
    name: "Real Time Status",
    description:
      "Keep your clients informed with status alerts before, during and after the service.",
    icon: StatusOnlineIcon,
  },
  {
    name: "Reviews and Tips",
    description:
      "Get valuable feedback and collect tips automatically after each service.",
    icon: StarIcon,
  },
  {
    name: "Driver App",
    description:
      "Dedicated app for your service pros in the field to view and update service requests.",
    icon: TruckIcon,
  },
  {
    name: "Recurring Schedules",
    description:
      "Let our system automatically create weekly, bi-weekly, or monthly recurring service requests.",
    icon: CalendarIcon,
  },
  {
    name: "Client App",
    description:
      "While labeled app on iOS and Android for your clients to place and manage orders.",
    icon: DeviceMobileIcon,
  },
];

const FeatureGrid = () => {
  const [index, setIndex] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(
      () => setIndex((index) => index + 1),
      3000 // every 3 seconds
    );
    return () => clearTimeout(intervalId);
  }, []);
  return (
    <div className="bg-primary-600 py-24 p-4">
      <div className="max-w-7xl mx-auto">
        <h1 className="text-center mb-20 text-5xl tracking-tight font-extrabold sm:text-5xl md:text-6xl">
          <span className="block md:inline text-gray-100 mb-3">Get new</span>
          <FancyUnderline className="text-white px-1 md:mx-2 mb-4 inline-block">
            <TextTransition
              text={TEXTS[index % TEXTS.length]}
              springConfig={presets.gentle}
              inline
            />
          </FancyUnderline>
          {/* <span className="inline-block md:inline text-white border-b-4 lg:border-b-8 pb-1 mb-4">
            {" "}
            <TextTransition
              text={TEXTS[index % TEXTS.length]}
              springConfig={presets.gentle}
              inline
            />{" "}
          </span> */}
          <span className="block md:mt-3 lg:mt-0 lg:inline text-gray-100">
            while you sleep.
          </span>
        </h1>
        <div className="grid grid-cols-1 gap-6 sm:grid-cols-2 md:grid-cols-3">
          {features.map((feature) => (
            <div
              key={feature.name}
              className="bg-primary-800 sm:hover:bg-primary-700 shadow p-6 md:p-8 rounded-xl sm:hover:scale-105 transition-all"
            >
              <div>
                <span className="flex items-center justify-center h-12 w-12 rounded-md bg-white bg-opacity-10">
                  <feature.icon
                    className="h-6 w-6 text-white"
                    aria-hidden="true"
                  />
                </span>
              </div>
              <div className="mt-3 md:mt-6">
                <h3 className="text-md md:text-lg font-medium text-white">
                  {feature.name}
                </h3>
                <p className="mt-2 text-sm md:text-base text-primary-100">
                  {feature.description}
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default FeatureGrid;
