const LogoCloud = () => {
  return (
    <div className="bg-gray-100">
      <div className="max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:px-8">
        <p className="text-center text-sm font-semibold uppercase text-gray-500 tracking-wide">
          Join thousands of happy customers nationwide
        </p>
        <div className="mt-6 grid grid-cols-2 gap-8 md:grid-cols-6 lg:grid-cols-5 items-center justify-center">
          <div className="col-span-1 flex justify-center md:col-span-2 lg:col-span-1 grayscale hover:grayscale-0 transition-all">
            <img className="h-10" src="/images/24-7_fixit.png" alt="Tuple" />
          </div>
          <div className="col-span-1 flex justify-center md:col-span-2 lg:col-span-1 grayscale hover:grayscale-0 transition-all">
            <img
              className="h-10"
              src="/images/cleveland_laundry_service.png"
              alt="Mirage"
            />
          </div>
          <div className="col-span-1 flex justify-center md:col-span-2 lg:col-span-1 grayscale hover:grayscale-0 transition-all">
            <img
              className="h-20"
              src="/images/element_detail.png"
              alt="StaticKit"
            />
          </div>
          <div className="col-span-1 flex justify-center md:col-span-2 md:col-start-2 lg:col-span-1 grayscale hover:grayscale-0 transition-all">
            <img
              className="h-10"
              src="/images/final_mile_technology.png"
              alt="Transistor"
            />
          </div>
          <div className="col-span-2 flex justify-center md:col-span-2 md:col-start-4 lg:col-span-1 grayscale hover:grayscale-0 transition-all">
            <img
              className="h-10"
              src="/images/soapbox_laundry.png"
              alt="Workcation"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default LogoCloud;
