import { CheckIcon } from "@heroicons/react/solid";
import Image from "next/image";
import Lottie from "react-lottie";

const LargeFeatureRow = ({
  badgeName,
  BadgeIcon,
  title,
  description,
  features,
  imageLocation,
  image,
  lottie,
  imageMaxWidth,
}) => {
  return (
    <div className="max-w-7xl mx-auto px-4 lg:px-8">
      <div className="flex flex-col md:flex-row justify-between items-center my-16 lg:my-28 ">
        <div
          className={`w-full sm:flex-1 mt-8 md:mt-0 ${
            imageLocation == "left"
              ? "order-2 md:order-1"
              : "order-2 md:order-2"
          } flex justify-center`}
        >
          <div className={`${imageMaxWidth ? imageMaxWidth : "w-full"}`}>
            {lottie && (
              <Lottie
                options={{
                  loop: true,
                  autoplay: true,
                  animationData: lottie,
                }}
                height={400}
                width={"100%"}
              />
            )}
            {image && (
              <Image
                layout="responsive"
                src={image}
                alt="Tap For Service Dashboard Page"
                className={`rounded-xl shadow-xl`}
              />
            )}
          </div>
        </div>
        <div
          className={`flex-1 ${
            imageLocation == "left"
              ? "order-1 md:order-2 md:pl-24"
              : "order-1 md:order-1 md:pr-24"
          }`}
        >
          <div className="my-4">
            <span className="rounded-md inline-block bg-gray-700 py-2 px-4">
              <div className="flex items-center space-x-3">
                <BadgeIcon className="h-6 w-6 text-white" aria-hidden="true" />
                <h2 className="text-lg font-extrabold text-white">
                  {badgeName}
                </h2>
              </div>
            </span>
            <div className="mt-6">
              <h2 className="text-4xl font-bold text-gray-900">{title}</h2>
              <p className="mt-4 text-lg text-gray-500">{description}</p>
              <div className="mt-6">
                {features.map((feature, index) => (
                  <div
                    className="flex items center text-gray-700 text-md"
                    key={index}
                  >
                    <CheckIcon className="w-6 h-6 mr-3 text-green-700" />{" "}
                    {feature}
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LargeFeatureRow;
